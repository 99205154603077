<template>
  <DefaultAmpLayout
    style="
      --main-color: #2962f9;
      --hover-color: #264eb1;
      --hover-color-2: #c43976;
      --background-color: #f8f9fb;
      --bg-color-substrate: #f8f9fb;
      --bg-color-substrate-2: #f3f5fc;
      --bg-color-substrate-3: #f3f5fc;
      --main-color-2: #2962f9;
    "
  >
    <slot />
  </DefaultAmpLayout>
</template>

<script setup lang="ts">
  import DefaultAmpLayout from './default.amp.vue';

  useHead({
    meta: [
      {
        hid: 'theme-color',
        name: 'theme-color',
        content: '#2962f9',
      },
    ],
  });
</script>
